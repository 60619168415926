import React from 'react';
import Lazy from 'services/loadable';
import { RouteComponentProps } from 'react-router';

export type IRouter = {
  path?: string;
  exact: boolean;
  meta?: {
    auth: true;
  },
  component: React.ComponentType<RouteComponentProps> | React.ComponentType;
};

const routes: IRouter[] = [
  { path: '/auth/login', component: Lazy(() => import('pages/auth/login')), exact: true, },
  { path: `/cms/dashboard`, component: Lazy(() => import('pages/dashboard')), exact: true, meta: { auth: true } },
  { path: `/cms/menu`, component: Lazy(() => import('pages/menu')), exact: true, meta: { auth: true } },
  { path: `/cms/menu/create`, component: Lazy(() => import('pages/menu/create')), exact: true, },
  { path: `/cms/menu/update/:id`, component: Lazy(() => import('pages/menu/update')), exact: true, meta: { auth: true } },
  { path: `/cms/menu/group`, component: Lazy(() => import('pages/menu-group')), exact: true, meta: { auth: true } },
  { path: `/cms/menu/group/create`, component: Lazy(() => import('pages/menu-group/create')), exact: true, meta: { auth: true } },
  { path: `/cms/menu/group/update/:id`, component: Lazy(() => import('pages/menu-group/update')), exact: true, meta: { auth: true } },
  { path: `/cms/user/list`, component: Lazy(() => import('pages/user')), exact: true, meta: { auth: true } },
  { path: `/cms/user/create`, component: Lazy(() => import('pages/user/create')), exact: true, meta: { auth: true } },
  { path: `/cms/user/update/:id`, component: Lazy(() => import('pages/user/update')), exact: true, meta: { auth: true } },
  { path: `/cms/user/group`, component: Lazy(() => import('pages/user-group')), exact: true, meta: { auth: true } },
  { path: `/cms/user/group/create`, component: Lazy(() => import('pages/user-group/create')), exact: true, meta: { auth: true } },
  { path: `/cms/user/group/update/:id`, component: Lazy(() => import('pages/user-group/update')), exact: true, meta: { auth: true } },
  { path: `/cms/content/:slug/:action?/:id?`, component: Lazy(() => import('pages/content')), exact: false, meta: { auth: true } },
  { path: `/cms/category`, component: Lazy(() => import('pages/category')), exact: true, meta: { auth: true } },
  { path: `/cms/category/create`, component: Lazy(() => import('pages/category/create')), exact: true, meta: { auth: true } },
  { path: `/cms/category/update/:id`, component: Lazy(() => import('pages/category/update')), exact: true, meta: { auth: true } },
  { path: `/cms/type`, component: Lazy(() => import('pages/type')), exact: true, meta: { auth: true } },
  { path: `/cms/type/create`, component: Lazy(() => import('pages/type/create')), exact: true, meta: { auth: true } },
  { path: `/cms/type/update/:id`, component: Lazy(() => import('pages/type/update')), exact: true, meta: { auth: true } },
  { path: `/cms/page`, component: Lazy(() => import('pages/page')), exact: true, meta: { auth: true } },
  { path: `/cms/page/create`, component: Lazy(() => import('pages/page/create')), exact: true, meta: { auth: true } },
  { path: `/cms/page/update/:id`, component: Lazy(() => import('pages/page/update')), exact: true, meta: { auth: true } },
];

export default routes;
