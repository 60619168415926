import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';
import SettingsReducer from './settings/reducers';
import UserReducer from './user/reducers';

const Reducers = (history: History): Reducer => combineReducers({
  router: connectRouter(history),
  UserReducer,
  SettingsReducer,
});

export default Reducers;
