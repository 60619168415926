import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, Modal, ModalProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { corporate_cms as api } from '@mobicom/dti';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const Resources: Array<api.Resource> = ['menu', 'menuGroup', 'type', 'page', 'category', 'content', 'user', 'userGroup'];

type AppRoleProps = {
  visible?: boolean;
  title?: string;
  roles?: api.Roles;
  onOk: (e: api.Roles) => void;
  onCancel: () => void;
};

type IDataSource = {
  key: number;
  resource: api.Resource;
  c: boolean;
  r: boolean;
  u: boolean;
  d: boolean;
};

type IAction = 'c' | 'r' | 'u' | 'd';

const AppRole: React.FC<AppRoleProps> = (props: AppRoleProps) => {
  const { visible = true, roles = [], title, onOk, onCancel } = props;
  const [dataSource, setDataSource] = useState<Array<IDataSource>>([]);

  const getValue = useCallback((resource: string, action: IAction): boolean => {
    const role = roles && roles.find((rec) => rec.resource === resource);
    if (role) {
      return role.role.indexOf(action) !== -1;
    }
    return false;
  }, [roles]);

  useEffect(() => {
    const data = Resources.map<IDataSource>((rec: string, idx: number) => {
      const resource = rec as api.Resource;
      return {
        key: idx,
        resource,
        c: getValue(resource, 'c'),
        r: getValue(resource, 'r'),
        u: getValue(resource, 'u'),
        d: getValue(resource, 'd'),
      };
    });

    setDataSource(data);
  }, [getValue]);

  const onChangeValue = (val: boolean, rec: IDataSource, action: IAction) => {
    rec[action] = val;

    const data = [...dataSource];
    data[rec.key] = rec;

    setDataSource(data);
  };

  const format = (rec: IDataSource) => {
    const c = `${rec.c ? 'c' : '-'}`;
    const r = `${rec.r ? 'r' : '-'}`;
    const u = `${rec.u ? 'u' : '-'}`;
    const d = `${rec.d ? 'd' : '-'}`;

    return `${c}${r}${u}${d}`;
  };

  const onSave = () => {
    const per: api.Roles = dataSource.map((rec) => {
      return {
        resource: rec.resource,
        role: format(rec)
      };
    });
    onOk(per);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Resource',
      key: 'resource',
      dataIndex: 'resource',
    },
    {
      title: 'Create',
      key: 'create',
      dataIndex: 'c',
      align: 'center',
      render: (val, rec: IDataSource) => (
        <Checkbox checked={rec.c} onChange={(e: CheckboxChangeEvent) => onChangeValue(e.target.checked, rec, 'c')} />
      ),
    },
    {
      title: 'Read',
      key: 'read',
      dataIndex: 'r',
      align: 'center',
      render: (val, rec: IDataSource) => (
        <Checkbox checked={rec.r} onChange={(e) => onChangeValue(e.target.checked, rec, 'r')} />
      ),
    },
    {
      title: 'Update',
      key: 'update',
      dataIndex: 'u',
      align: 'center',
      render: (val, rec: IDataSource) => (
        <Checkbox checked={rec.u} onChange={(e) => onChangeValue(e.target.checked, rec, 'u')} />
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      dataIndex: 'd',
      align: 'center',
      render: (val, rec: IDataSource) => (
        <Checkbox checked={rec.d} onChange={(e) => onChangeValue(e.target.checked, rec, 'd')} />
      ),
    },
  ];

  const RoleModalProps: ModalProps = {
    title: title || "Хандах эрхийн тохиргоо",
    centered: true,
    visible,
    onOk: onSave,
    onCancel
  };

  return (
    <Modal {...RoleModalProps}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </Modal>
  );
};

export default AppRole;
