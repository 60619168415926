import React, { createContext, useContext, useState } from 'react';
import { corporate_cms_auth } from '@mobicom/dti';
import { Locale } from 'antd/es/locale-provider';

export type AppCtxState = {
  loggedUser?: corporate_cms_auth.ITokenData | null;
  theme?: 'light' | 'dark';
  locale?: Locale
} | null;

export type AppCtxProps = {
  appState: AppCtxState;
  changeAppState: (state: AppCtxState) => void;
};

const defaultValue: AppCtxProps = {
  appState: null,
  changeAppState: () => { }
};

export const AppCtx = createContext<AppCtxProps>(defaultValue);
export const useAppCtx = (): AppCtxProps => useContext(AppCtx);

/* eslint-disable */
export const AppCtxProvider: React.FC = ({ children }) => {
  const [appState, setState] = useState(defaultValue.appState);
  const changeAppState = (newState: AppCtxState) => setState((state) => state = newState);

  return (
    <AppCtx.Provider value={{ appState, changeAppState }}>
      {children}
    </AppCtx.Provider>
  );
};
