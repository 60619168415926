import { message } from 'antd';
import ProLayout, { MenuDataItem, Settings } from '@ant-design/pro-layout';
import defaultSettings from '@ant-design/pro-layout/lib/defaultSettings';
import React, { useState } from 'react';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import MobicomLogo from 'assets/mobicom-logo.png';
import { footerText } from 'constant';
import { AppMenu, AppProfile } from 'components';
import { useAppCtx } from 'contexts';
import { corporate_cms_auth as api, corporate_cms_type as apiType } from "@mobicom/dti";
import http from 'services/http';
import styles from './styles.module.less';

const MainLayout: React.FC<RouteComponentProps> = ({ children, ...props }) => {
  const history = useHistory();
  const { appState, changeAppState } = useAppCtx();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [settings] = useState<Partial<Settings>>({
    ...defaultSettings,
    headerTheme: 'dark',
    navTheme: 'dark',
    fixedHeader: true,
    fixSiderbar: true,
  });

  const getMenus = async (): Promise<Array<MenuDataItem>> => {
    try {
      const resp = await http.get<apiType.list.IResponse>('/type/all');
      return AppMenu(resp.types);
    } catch (error) {
      message.error(error.message);
      return [];
    }
  };

  const onPageChange = async () => {
    try {
      if (appState?.loggedUser) return;

      const loggedUser = await http.post<api.ITokenData>('/auth/verify');

      changeAppState({
        loggedUser
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <ProLayout
      {...props}
      {...settings}
      title=""
      logo={MobicomLogo}
      siderWidth={280}
      disableMobile={false}
      onMenuHeaderClick={() => history.push('/')}
      // menuHeaderRender={(logoDom, titleDom) => (
      //   <Link to="/" className="left-logo">
      //     {logoDom}
      //     {titleDom}
      //   </Link>
      // )}
      collapsed={collapsed}
      onCollapse={(v: boolean) => setCollapsed(v)}
      onPageChange={onPageChange}
      menu={{
        request: async () => await getMenus()
      }}
      menuItemRender={(menuItemProps, defaultDom) => {
        return menuItemProps.isUrl ? defaultDom : <Link to={menuItemProps.path || '/'}>{defaultDom}</Link>;
      }}
      rightContentRender={() => <AppProfile />}
      footerRender={() => <div className={styles.footer}>{footerText}</div>}
      contentStyle={{ overflow: 'auto', height: 'calc(100vh - 125px)' }}
    >
      {children}
    </ProLayout>
  );
};

export default withRouter(MainLayout);
