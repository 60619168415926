import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { footerText } from 'constant';
import styles from './styles.module.less';

const AuthLayout: React.FC<RouteComponentProps> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.header} />
    <div className={styles.content}>{children}</div>
    <div className={styles.footer}>
      <h4 style={{ color: '#fff' }}>{footerText}</h4>
    </div>
  </div>
);

export default withRouter(AuthLayout);
