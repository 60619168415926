import { OSClient } from '@mobicom/s3-sdk';

namespace S3 {
    export const client = new OSClient({
        ACCESS_ID: process.env.REACT_APP_S3_ACCESS_ID,
        BUCKET_NAME: process.env.REACT_APP_S3_BUCKET_NAME,
        REGION: process.env.REACT_APP_S3_REGION,
        SECRET_KEY: process.env.REACT_APP_S3_SECRET_KEY
    });

    export const getObjectName = (url: string): string => {
        if (!url) return '';
        const path = url.split('/');
        return path[path.length - 1];
    };
}

export default S3;