import { Rule, RuleObject } from "antd/lib/form";

const formRule = (props?: RuleObject): Rule[] => {
  return [{
    required: props?.required || false,
    max: props?.max,
    whitespace: true,
    type: props?.type || "string",
  }];
};

export default formRule;