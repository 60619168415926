import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Upload, message, UploadProps, Image, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib/upload/interface';
// import { v4 as uuidv4 } from 'uuid';
import { S3 } from 'services';

interface AppUploaderProps {
    mimetypes?: string[];
    size?: number; // Megabyte
    count?: number;
    images?: string[];
    handleChange: Dispatch<SetStateAction<string | null>>;
}

const AppUploader: React.FC<AppUploaderProps> = ({ mimetypes = [], size = 1, count = 1, images = [], handleChange }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [fileListCount, setFileListCount] = useState<number>(0);
    const [preview, setPreview] = useState<{ visible?: boolean; title?: string; image?: string }>();

    // const getBase64 = (img: RcFile, callback: (file: string) => void) => {
    //     const reader = new FileReader();
    //     reader.onload = () => callback(reader.result as string);
    //     reader.readAsDataURL(img);
    // };

    const beforeUpload = (file: RcFile) => {
        const isAllowedType = mimetypes.includes(file.type);
        if (!isAllowedType) {
            message.error('Not supported file type!');
            return Upload.LIST_IGNORE;
        }

        const isLtSize = file.size / 1024 / 1024 < size;
        if (!isLtSize) {
            message.error(`File must smaller than ${size}MB!`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };

    const onChange = async (info: UploadChangeParam) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            setFileListCount(fileListCount + 1);
            handleChange(await S3.client.getUrl({ name: info.file.name }));
        }
        if (info.file.status === 'error') {
            setLoading(false);
            message.error(info.file.error.message || 'file not uploaded');
        }
    };

    const upload = async (options: any) => {
        const { file, onSuccess, onError } = options;
        try {
            // const ext = file.name.split('.').pop();
            // const filename = `${uuidv4()}.${ext}`;
            await S3.client.put({
                name: file.name,
                data: file
            });
            onSuccess(true);
        } catch (error) {
            onError(error.message || 'file not upload');
        }
    };

    const onRemove = async (file: UploadFile) => {
        try {
            setIsDelete(true);
            await S3.client.delete({ name: file.name });
            setFileListCount(fileListCount - 1);
            handleChange(null);
            setIsDelete(false);
        } catch (error) {
            setIsDelete(false);
            message.error(error.message);
        }
    };

    const onPreview = async (file: UploadFile) => {
        const url = file.url || await S3.client.getUrl({ name: file.name });
        setPreview({
            visible: true,
            title: file.name,
            image: url
        });
        // getBase64(file.originFileObj, (src: string) => {
        //     const img = Image({ src });
        //     const imgWindow = window.open(src);
        //     imgWindow?.document.write();
        // });
    };

    const Props: UploadProps = {
        maxCount: count,
        listType: 'picture-card',
        showUploadList: {
            removeIcon: isDelete ? <LoadingOutlined style={{ color: 'white' }} /> : <DeleteOutlined />
        },
        defaultFileList: images.map((rec: string, i) => ({
            uid: `${i}`,
            name: S3.getObjectName(rec),
            url: rec,
            status: 'done',
        })) as UploadFile[],
        // disabled: list.length >= count,
        beforeUpload,
        onChange,
        onRemove,
        onPreview,
        customRequest: upload
    };

    useEffect(() => {
        setFileListCount(images.length);
        return () => {
        };
    }, [images]);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <Upload {...Props}>
                {fileListCount >= count ? null : uploadButton}
            </Upload>
            <Modal
                visible={preview?.visible}
                title={preview?.title}
                footer={null}
                centered={true}
                onCancel={() => setPreview({ visible: false, image: undefined })}
            >
                <Image style={{ width: '100%' }} src={preview?.image} />
            </Modal>
        </>
    );
};

export default AppUploader;