import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
  const history = useHistory();
  return (
    <Result
      title="403"
      status="403"
      subTitle="403"
      extra={
        <Button type="primary" onClick={() => history.replace('/')}>back to home</Button>
      }
    />
  );
};

export default NotFound;
