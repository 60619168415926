import Loadable from 'react-loadable';
import { Loading } from 'components';
import React from 'react';
import { RouteComponentProps } from 'react-router';

type Component = React.ComponentType<RouteComponentProps> | React.ComponentType;

const lazyComponent = (loader: () => Promise<any>): Component => Loadable({
  loader,
  delay: false,
  loading: () => <Loading fill={false} />,
});

export default lazyComponent;