import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Auth from './auth';
import Main from './main';
import Public from './public';

const Layout: React.FC<RouteComponentProps> = ({ children, location }) => {
  const { pathname } = location;
  let LayoutComponent = Public;

  if (pathname.startsWith('/auth')) {
    LayoutComponent = Auth;
  } else if (pathname.startsWith('/cms')) {
    LayoutComponent = Main;
  }

  return <LayoutComponent>{children}</LayoutComponent>;
};

export default withRouter(Layout);
