import Cookies from 'universal-cookie';

const cookies = new Cookies();

namespace cookie {
    export const getCookie = (name: string): any => {
        return cookies.get(name);
    };

    export const setCookie = (name: string, value: string): void => {
        cookies.set(name, value);
    };

    export const removeCookie = (name: string): void => {
        cookies.remove(name);
    };
}

export default cookie;