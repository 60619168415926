import React from 'react';
import Layout from 'layouts';
import { NotAuthorized, NotFound } from 'pages/errors';
import { Redirect, Switch } from 'react-router-dom';
import routes from 'router';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { AuthGuard } from 'guards';

const App: React.FC = () => {
  return (
    <Layout>
      <GuardProvider guards={[AuthGuard]}>
        <Switch>
          <GuardedRoute exact path="/" render={() => <Redirect to="/cms/dashboard" />} />
          <GuardedRoute exact path="/auth" render={() => <Redirect to="/auth/login" />} />
          {routes.map((route) => (
            <GuardedRoute
              key={route.path}
              path={route.path}
              component={route.component}
              meta={route.meta || { auth: false }}
              exact={!!route.exact}
            />
          ))}
          <GuardedRoute exact path="/cms/403" component={NotAuthorized} />
          <GuardedRoute component={NotFound} />
        </Switch>
      </GuardProvider>
    </Layout>
  );
};

export default App;
