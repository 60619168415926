import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';

interface IPropTypes {
  onEdit: () => void;
  onDelete: () => void;
}

const AppControlButton: React.FC<IPropTypes> = ({ onEdit, onDelete }) => {

  const menu = (
    <Menu style={{ minWidth: '150px' }}>
      <Menu.Item key={1}>
        <a role="button" tabIndex={0} onClick={() => { onEdit(); }}>
          <EditOutlined /> Засах
        </a>
      </Menu.Item>
      <Menu.Item key={2}>
        <a role="button" tabIndex={0} onClick={() => { onDelete(); }} style={{ color: 'red' }}>
          <DeleteOutlined /> Устгах
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <SettingOutlined style={{ fontSize: '16px' }} />
    </Dropdown>
  );
};

export default AppControlButton;