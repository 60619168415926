import { message } from 'antd';
import { Next } from 'react-router-guards';
import { cookie } from 'services';

// eslint-disable-next-line
const AuthGuard = async (to: any, from: any, next: Next): Promise<void> => {
    if (to && to.meta && to.meta.auth) {
        const token = cookie.getCookie('__token');
        if (!token) {
            message.warn('Холболт салсан байна. Дахин нэвтрэнэ үү!');
            return next.redirect('/auth');
        }

        return next();
    }

    return next();
};

export default AuthGuard;