import React from 'react';
import { IJodit, Jodit } from "jodit";
import JoditEditor from "jodit-react";

type HTMLEditorProps = {
  value?: string;
  onChange?: (e: any) => void;
};

const JoditHTML: React.FC<HTMLEditorProps> = ({ value, onChange }) => {
  const config: IJodit['options'] = {
    ...Jodit.defaultOptions,
    readonly: false,
    enableDragAndDropFileToEditor: true,
    allowTabNavigation: false,
    uploader: {
      ...Jodit.defaultOptions.uploader,
      insertImageAsBase64URI: true,
    },
    removeButtons: ['font'],
    colorPickerDefaultTab: 'color',
    spellcheck: false,
    toolbarButtonSize: 'small',
  };

  const onJoditChange = (data: string) => {
    if (data && onChange !== undefined) {
      const newValue = data.replace(/font-family/g, 'f');

      if (data.includes('jodit-content')) {
        onChange(newValue);
      } else {
        onChange(`<div class="jodit-content">${newValue}</div>`); // removing all font family styles
      }
    }
  };

  return (
    <JoditEditor
      value={value || ''}
      onBlur={onJoditChange}
      config={config}
    />
  );
};

export default JoditHTML;