import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Divider, Dropdown, Menu, message } from 'antd';
import classnames from 'classnames';
import { useAppCtx } from 'contexts';
import React from 'react';
// import { useSelector } from 'react-redux';
// import { ReduxInterface, UserInterface } from 'interfaces';
import { useHistory } from 'react-router-dom';
import http from 'services/http';
import styles from './styles.module.css';

const AppProfile: React.FC = () => {
  const history = useHistory();
  const { appState } = useAppCtx();

  // const { firstname, lastname } = useSelector<ReduxInterface, UserInterface>(
  //   (state1: ReduxInterface) => state1.UserReducer
  // );

  const logout = async () => {
    try {
      await http.post('/auth/logout');
      history.push('/auth/login');
    } catch (error) {
      message.error(error.message);
    }
  };

  const avatar = (appState?.loggedUser && appState.loggedUser.username) || 'AU';

  const menu = (
    <Menu selectable={false}>
      <Menu.Item className={styles.item}>
        <div className={classnames('flex-row flex-center flex-start')}>
          <Avatar shape="circle" className={classnames('noselect pointer', styles.avatar)}>
            {avatar.toUpperCase()}
          </Avatar>
          <div className={styles.names}>
            {avatar.toUpperCase()}
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout} className={styles.item}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Divider type="vertical" />
      <div className={classnames('flex-row flex-end')}>
        <Dropdown overlay={menu} trigger={['click']}>
          <div className={classnames('flex-row flex-center m_l_1')}>
            <Avatar shape="circle" className={classnames('noselect pointer', styles.avatar)}>
              {avatar.toUpperCase()}
            </Avatar>
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default AppProfile;
