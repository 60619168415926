import React from 'react';
import { MenuDataItem } from '@ant-design/pro-layout';
import { AreaChartOutlined, AppstoreOutlined, UserOutlined, MenuOutlined, AppstoreAddOutlined, BookOutlined, NumberOutlined, } from '@ant-design/icons';
import { corporate_cms_type as api } from '@mobicom/dti';

const AppMenu = (types: Array<api.IType> = []): Array<MenuDataItem> => {
    const contentChilds = types.map((rec: api.IType) => (
        { path: `/cms/content/${rec.tag}`, name: rec.title, key: rec.tag }
    ));

    return [
        { path: `/cms/dashboard`, key: 'dashboard', name: 'Dashboard', icon: <AreaChartOutlined style={{ fontSize: 16 }} /> },
        { path: `/cms/menu`, name: 'Menu', key: 'menu', icon: <MenuOutlined style={{ fontSize: 16 }} /> },
        { path: `/cms/page`, name: 'Page', key: 'page', icon: <NumberOutlined style={{ fontSize: 16 }} /> },
        { path: `/cms/category`, name: 'Category', key: 'category', icon: <BookOutlined style={{ fontSize: 16 }} /> },
        { path: `/cms/type`, name: 'Type', key: 'type', icon: <AppstoreAddOutlined style={{ fontSize: 16 }} /> },
        { path: `/cms/content`, name: 'Content', key: 'content', icon: <AppstoreOutlined style={{ fontSize: 16 }} />, children: contentChilds },
        {
            path: `/cms/user`,
            name: 'User',
            key: 'user',
            icon: <UserOutlined style={{ fontSize: 16 }} />,
            children: [
                { path: `/cms/user/list`, name: 'User', key: 'user-list', icon: <AreaChartOutlined style={{ fontSize: 16 }} /> },
                { path: `/cms/user/group`, name: 'User Group', key: 'user-group', icon: <AreaChartOutlined style={{ fontSize: 16 }} /> },
            ]
        },
    ];
};

export default AppMenu;