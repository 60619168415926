// import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import styles from './styles.module.less';

interface IProps {
  fill?: boolean;
}

// const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading: React.FC<IProps> = ({ fill = true }) => (
  <div className={styles.container} style={{ width: fill ? '100vw' : '100%', height: fill ? '100vh' : '100%' }}>
    <Spin className={styles.loader} /> Loading...
  </div>
);

export default Loading;
