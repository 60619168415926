import { Pagination, PaginationProps } from 'antd';
import React from 'react';
import styles from './styles.module.less';

const AppPagination: React.FC<PaginationProps> = (props) => {
  const DefaultPaginationProps: PaginationProps = {
    size: 'small',
    defaultPageSize: 50,
    pageSizeOptions: ["30", "50", "100"],
    responsive: true,
    showSizeChanger: true,
    hideOnSinglePage: false,
    ...props
  };

  return (
    <Pagination {...DefaultPaginationProps} className={styles.app_pagination} />
  );
};

export default AppPagination;
