import React from 'react';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from 'redux/reducers';
import sagas from 'redux/sagas';
import reportWebVitals from 'reportWebVitals';
import { AppCtxProvider } from 'contexts';
import { ConfigProvider } from 'antd';
import mnMN from 'antd/lib/locale/en_US';
import App from 'app';

import 'styles/index.less';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppCtxProvider>
        <ConfigProvider locale={mnMN}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </AppCtxProvider>
    </ConnectedRouter>
  </Provider>,
  window.document.getElementById('root')
);

reportWebVitals();

export { store, history };
