import { UserInterface } from 'interfaces';
import { Action, Reducer } from 'redux';

export enum Actions {
  SET_STATE = 'user/SET_STATE',
  LOGIN = 'user/LOGIN',
  LOGOUT = 'user/LOGOUT',
  GET_AUTH_USER = 'user/GET_AUTH_USER',
  REGISTER = 'user/register',
}

export interface DispatchAction extends Action {
  payload: Partial<UserInterface>;
}

const initialState: UserInterface = {
  loading: false,
  authorized: false,
  ipAddress: '',
};

const UserReducer: Reducer<UserInterface, DispatchAction> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_STATE:
      return { ...state, ...action.payload };
    case Actions.REGISTER:
      return { ...state };
    default:
      return state;
  }
};

export default UserReducer;
